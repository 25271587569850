import React from "react"

import Code from "./Code";
import Text from "./Text";
import Label from "./Label";
import Number from "./Number";
import Date from "./Date";
import DateTime from "./DateTime";
import Location from "./Location";
import Party from "./Party";
import ShipmentItem from "./ShipmentItem";
import Guarantee from "./Guarantee";
import Bool from "./Bool";

import FieldHelpText from "../modal/FieldHelpText";

import AuthManager from "../../../utils/AuthManager";

import Tooltip from '@material-ui/core/Tooltip';

// todo implement starred formats (currently not used in any form)
// FORMAT_TYPES = [
//     FORMAT_TYPE_CODE,
//     FORMAT_TYPE_TEXT,
//     FORMAT_TYPE_LABEL,
//*     FORMAT_TYPE_PHONE,
//*     FORMAT_TYPE_EMAIL,
//     FORMAT_TYPE_NUMBER,
//     FORMAT_TYPE_DATE,
//     FORMAT_TYPE_BOOL,
//     FORMAT_TYPE_LOCATION,
//*     FORMAT_TYPE_CENTS,
//     FORMAT_TYPE_PARTY,
//     FORMAT_TYPE_SHIPMENT_ITEM,
//     FORMAT_TYPE_GUARANTEE
// ]

export default class Field extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            index: props.index,
            group: props.group,
            editable: props.editable,
            submission: props.submission
        }

        this.field = React.createRef()
    }

    componentWillReceiveProps(props) {
        this.setState(props, () => {
            let { field, group } = this.state
            if(field.answer && field.answer.group !== group){
                field.answer.group = group
                this.props.onUpdated(field)
            }
        })
    }

    getError(){
        let {
            field
        } = this.state
        if(!this.field?.current || field.format.type === "label"){
            return null
        }

        let error = null
        let errorMessage = this.field.current.getError()

        if(errorMessage){
            error = {
                message: errorMessage,
                field: this.state.field,
            }
            this.setState({ error: errorMessage })
        }
        return error
    }

    _getInputContent(){
        let {
            field,
            index,
            group,
            editable,
            submission,
        } = this.state

        let formatType = field.format.type

        let inputProps = {
            field,
            index,
            editable,
            submission,
            ref: this.field,
            onUpdated: fieldData => {
                if(fieldData.answer){
                    fieldData.answer.group = group
                }
                this.setState({ error: null })
                this.props.onUpdated(fieldData)
            },
            onBlur: () => {
                this.getError()
                this.props.onBlur()
            }
        }

        switch (formatType){
            case "code":            return <Code {...inputProps}/>
            case "label":           return <Label {...inputProps}/>
            case "party":           return <Party {...inputProps}/>
            case "text":            return <Text {...inputProps}/>
            case "number":          return <Number {...inputProps}/>
            case "location":        return <Location {...inputProps}/>
            case "shipment_item":   return <ShipmentItem {...inputProps}/>
            case "guarantee":       return <Guarantee {...inputProps}/>
            case "date":            return <Date {...inputProps}/>
            case "datetime":        return <DateTime {...inputProps}/>
            case "bool":            return <Bool {...inputProps}/>

            default:                return <p> unsupported type: {formatType}</p>
        }
    }

    _renderHeader(field){
      let { submission } = this.state
      let helpText = field.help_text

      let title = field.title
      if(field.box_code && field.format.type != "party"){
        title += ` [${field.box_code}]`
      }
      if(field.is_required && !submission?.template){
        title += "*"
      }

      if(!helpText && AuthManager.currentUser.user.role != "admin"){
        return <span className="me-3">{title}</span>
      }
      return (
        <Tooltip
          title={<p style={{ fontSize: 12, padding: 8, marginBottom: 0}}>{helpText}</p>}
          fontSize={16}
          placement="top"
          arrow
          className="mb-1"
        >
          <a
            className="text-hover-primary"
            onClick={() => {
              if(AuthManager.currentUser.user.role != "admin"){
                return
              }
              this.setState({ showFieldHelpTextModal: true })
            }}
          >
            <span className="me-3">{title}</span>
          </a>
        </Tooltip>
      )

    }


    render(){
        let {
            field,
            error,
            submission,
            showFieldHelpTextModal
        } = this.state

        let inputContent = this._getInputContent()

        return (
            <div className={"col"}>
                <label className="mb-2 mt-5">
                  {this._renderHeader(field)}
                </label>

                {inputContent}
                {
                    error &&
                    <div className="fv-plugins-message-container invalid-feedback">
                        <div data-field="business_name" data-validator="notEmpty">{error}</div>
                    </div>
                }
                { showFieldHelpTextModal &&
                  <FieldHelpText
                    show={showFieldHelpTextModal}
                    field={field}
                    onHide={() => this.setState({ showFieldHelpTextModal: false })}
                    onUpdated={() => this.setState({ showFieldHelpTextModal: false })}
                  />
                }
            </div>
        )
    }
}
