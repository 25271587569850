import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import LeftMenu from "./components/structure/LeftMenu";
import Header from "./components/structure/Header";
import Footer from "./components/structure/Footer";

import DevAlert from "./components/alert/DevAlert";

import Dashboard from "./Dashboard"
import Analytics from "./Analytics"
import Users from "./Users"
import CodeLists from "./CodeLists";
import Settings from "./Settings"
import Submissions from "./Submissions";
import BulkUploads from "./BulkUploads";
import CodeListKey from "./CodeListKey";
import Accounts from "./Accounts";
import RestrictedCodes from "./RestrictedCodes";
import DeclarationForm from "./DeclarationForm";

export default class App extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if(document.getElementById("root").className === "bg-body"){
      document.getElementById("root").className = "header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed"
    }
  }

  _renderRoutes() {
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/submissions" component={() => <Submissions/>} />
        <Route exact path="/import-submissions" component={() => <Submissions imports={true} />} />
        <Route exact path="/export-submissions" component={() => <Submissions imports={false} />} />
        <Route exact path="/submission/:submissionId" component={DeclarationForm} />
        <Route exact path="/bulk-uploads" component={BulkUploads} />
        <Route exact path="/accounts" component={Accounts} />
        <Route exact path="/analytics" component={Analytics} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/code-lists" component={CodeLists} />
        <Route exact path="/code-list/:listId" component={CodeListKey} />
        <Route exact path="/restricted-codes" component={RestrictedCodes} />
        <Route exact path="/settings" component={Settings} />
        <Redirect to="/404" />
      </Switch>
    )
  }

  render() {
    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <LeftMenu/>
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <Header/>

              <DevAlert />

              <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-xxl">
                    {this._renderRoutes()}
                  </div>
                </div>
              </div>
              <Footer/>
            </div>
          </div>
        </div>
      </>
    )
  }

}
