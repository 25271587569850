import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";

export default class AddOns extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      company: props.company,
      subscriptionAddOns: props.company.subscription.add_ons
    }
  }

  componentDidMount() {

  }


  _update(){
    let { company, subscriptionAddOns } = this.state
    this.setState({loading: true})

    Backend.updateAddOns(company, subscriptionAddOns)
    .then(party => {
      this.setState({loading: false})
      Notify.success("Account Updated")
      this.props.onSubmit()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }



  render() {
    let {
      show,
      loading,
      subscriptionAddOns,
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Ons</Modal.Title>
          </Modal.Header>

          <Modal.Body>
          {
                ADD_ONS.map(addOn => {
                  let { label, description, key, type, icon } = addOn

                  let subscriptionAddOn = subscriptionAddOns[key]
                  return (
                    <>
                      <label className="d-flex flex-stack mb-5 cursor-pointer">
                        <span className="d-flex align-items-center me-2">
                          <span className="symbol symbol-50px me-6">
                            <span className="symbol-label">
                              <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none"
                                >
                                  {icon}
                                </svg>
                              </span>
                            </span>
                          </span>
                          <span className="d-flex flex-column">
                            <span className="fw-bolder text-gray-800 text-hover-primary fs-5">{label}</span>
                            <span className="fs-6 fw-bold text-muted">{description}</span>
                          </span>
                        </span>
                        <span className="form-check form-check-custom form-check-solid is-valid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="account_plan"
                            value="1"
                            checked={subscriptionAddOn.enabled}
                            onChange={e => {
                              subscriptionAddOn.enabled = e.target.checked
                              subscriptionAddOns[key] = subscriptionAddOn
                              this.setState({ subscriptionAddOns })
                            }}
                          />
                        </span>
                      </label>
                    </>
                  )
                })
              }
          </Modal.Body>

          <Modal.Footer>
            <div className="text-center pt-15" bis_skin_checked="1">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => this.props.onHide()}
              >
                Discard
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
                onClick={e => {
                  e.preventDefault()
                  this._update()
                }}
              >
                <span className="indicator-label">Update Account</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </Modal.Footer>


        </Modal>
      </>
    )
  }

}

const ADD_ONS = [
  {
    label: "Training Mode",
    description: "Your users can train themselves with our training mode",
    key: "training",
    type: "checkbox",
    icon: (
      <>
        <path
          d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
          fill="black"/>
        <path
          opacity="0.3"
          d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
          fill="black"/>
      </>
    )
  },
  {
    label: "Exports",
    description: "Create export declarations",
    key: "exports",
    type: "checkbox",
    icon: (
      <>
        <path
          d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM11 6.59998V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V6.59998H11Z"
          fill="currentColor"/>
        <path
          opacity="0.3" d="M7 6.59999H17L12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3L7 6.59999Z"
          fill="currentColor"/>
      </>
    )
  },
  {
    label: "Imports",
    description: "Create import declarations",
    key: "imports",
    type: "checkbox",
    icon: (
      <>
        <path
          d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z"
          fill="currentColor"/>
        <path
          opacity="0.3" d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z"
          fill="currentColor"/>
      </>
    )
  },
  {
    label: "Analytics",
    description: "Oversee your accounts trends",
    key: "analytics",
    type: "checkbox",
    icon: (
      <>
        <path
          d="M13 10.9128V3.01281C13 2.41281 13.5 1.91281 14.1 2.01281C16.1 2.21281 17.9 3.11284 19.3 4.61284C20.7 6.01284 21.6 7.91285 21.9 9.81285C22 10.4129 21.5 10.9128 20.9 10.9128H13Z"
          fill="black"/>
        <path
          opacity="0.3"
          d="M13 12.9128V20.8129C13 21.4129 13.5 21.9129 14.1 21.8129C16.1 21.6129 17.9 20.7128 19.3 19.2128C20.7 17.8128 21.6 15.9128 21.9 14.0128C22 13.4128 21.5 12.9128 20.9 12.9128H13Z"
          fill="black"/>
        <path
          opacity="0.3"
          d="M11 19.8129C11 20.4129 10.5 20.9129 9.89999 20.8129C5.49999 20.2129 2 16.5128 2 11.9128C2 7.31283 5.39999 3.51281 9.89999 3.01281C10.5 2.91281 11 3.41281 11 4.01281V19.8129Z"
          fill="black"/>
      </>
    )
  },
  {
    label: "Automated Imports",
    description: "Create import declarations from export declaration",
    key: "automated_imports",
    type: "checkbox",
    icon: (
      <>
        <path
          d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z"
          fill="currentColor"/>
        <path
          opacity="0.3" d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z"
          fill="currentColor"/>
      </>
    )
  },
  {
    label: "AI Verification",
    description: "Automatically verify uploaded invoices against declarations",
    key: "automated_verification",
    type: "checkbox",
    icon: (
      <>
        <path
          d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z"
          fill="currentColor"/>
        <path
          opacity="0.3" d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z"
          fill="currentColor"/>
      </>
    )
  },
]
