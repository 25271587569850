import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.IsDev = env.REACT_APP_DEV == "true"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/request-reset-password"
window.Api.User.ResetPassword         = window.Api.User.Base + "/reset-password"
window.Api.User.RefreshToken          = window.Api.User.Base + "/refresh-token"

window.Api.Companies                  = window.Api.Base + "/companies"
window.Api.CompanyMembers             = window.Api.Base + "/company-members"
window.Api.Files                      = window.Api.Base + "/files"
window.Api.Parties                    = window.Api.Base + "/parties"
window.Api.Codes                      = window.Api.Base + "/codes"
window.Api.Stats                      = window.Api.Base + "/stats"
window.Api.States                     = window.Api.Base + "/states"
window.Api.Lists                      = window.Api.Base + "/lists"
window.Api.Guarantees                 = window.Api.Base + "/guarantees"
window.Api.Forms                      = window.Api.Base + "/forms"
window.Api.Items                      = window.Api.Base + "/items"
window.Api.Submissions                = window.Api.Base + "/submissions"
window.Api.Authorisations             = window.Api.Base + "/authorisations"
window.Api.Currencies                 = window.Api.Base + "/currencies"
window.Api.ShipmentItems              = window.Api.Base + "/shipment-items"
window.Api.DocumentsRequest           = window.Api.Base + "/documents-request"
window.Api.Companies                  = window.Api.Base + "/companies"
window.Api.Admins                     = window.Api.Base + "/admins"
window.Api.BulkUploads                = window.Api.Base + "/bulk-uploads"
window.Api.Fields                     = window.Api.Base + "/fields"
window.Api.RestrictedCodes            = window.Api.Base + "/restricted-codes"
window.Api.Subscriptions              = window.Api.Base + "/subscriptions"
