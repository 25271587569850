import React from "react";
import StatCard from "./components/common/StatCard";
import DeclarationsTable from "./components/tables/SubmissionsTable";
import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthManager.currentUser,
    };
  }

  componentDidMount() {
    Backend.getOverallStats()
      .then((stats) => this.setState({ stats }))
      .catch((e) => Notify.error(e.message));
  }

  _renderDeclarationStat() {
    let { stats } = this.state;

    if (!stats) return null;

    return (
      <>
        <div className="row">
          <StatCard
            title={"Declarations Last 7 Days"}
            variation={"detail"}
            count={stats.overall.periods.seven_days.current.count}
            percentage={
              stats.overall.periods.seven_days.current.percentage_delta
            }
            className={
              stats.overall.periods.seven_days.current.count >
              stats.overall.periods.seven_days.previous.count
                ? "success"
                : "danger"
            }
          />
          <StatCard
            title={"Declarations Last 30 Days"}
            variation={"detail"}
            count={stats.overall.periods.thirty_days.current.count}
            percentage={
              stats.overall.periods.thirty_days.current.percentage_delta
            }
            className={
              stats.overall.periods.thirty_days.current.count >
              stats.overall.periods.thirty_days.previous.count
                ? "success"
                : "danger"
            }
          />
          <StatCard
            title={"Declarations Last 3 Months"}
            variation={"detail"}
            count={stats.overall.periods.ninety_days.current.count}
            percentage={
              stats.overall.periods.ninety_days.current.percentage_delta
            }
            className={
              stats.overall.periods.ninety_days.current.count >
              stats.overall.periods.ninety_days.previous.count
                ? "success"
                : "danger"
            }
          />
          <StatCard
            title={"Declarations Last Year"}
            variation={"detail"}
            count={stats.overall.periods.year.current.count}
            percentage={
              stats.overall.periods.year.current.percentage_delta * 100
            }
            className={
              stats.overall.periods.year.current.count >
              stats.overall.periods.year.previous.count
                ? "success"
                : "danger"
            }
          />
        </div>
      </>
    );
  }

  _renderStatSection() {
    let { currentUser } = this.state;

    return (
      <>
        <div className="col-md-6">{this._renderDeclarationStat()}</div>
      </>
    );
  }

  _renderStatBadgeSection(stats) {
    const statData = [
      {
        label: "Processed",
        value: stats.overall.verification.total_no,
        labelClass: "text-primary",
      },
      {
        label: "Verified",
        value: stats.overall.verification.passed_no,
        labelClass: "text-success",
      },
      {
        label: "Failed",
        value: stats.overall.verification.failed_no,
        labelClass: "text-danger",
      },
    ];

    return (
      <StatCard
        cardClassName={this.state.cardClassName}
        title={"AI Verified"}
        variation={"badge"}
        stats={statData}
      />
    );
  }

  render() {
    let { stats } = this.state;

    return (
      <>
        {this._renderDeclarationStat()}

        {stats && (
          <div className="row gb-5">
            <StatCard className={"success"} count={stats.overall.green} />

            <StatCard className={"warning"} count={stats.overall.orange} />

            <StatCard className={"danger"} count={stats.overall.red} />

            {this._renderStatBadgeSection(stats)}
          </div>
        )}

        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <DeclarationsTable
              title={"Recent Declarations"}
              subtitle={"Last 20 Declarations"}
              endpoint={`${window.Api.Submissions}?draft=false`}
            />
          </div>
        </div>
      </>
    );
  }
}
